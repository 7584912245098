<template>
  <b-card no-body>
    <b-row class="px-2">
      <!-- Per Page -->
      <b-col
        cols="12"
        md="6"
        class="d-flex align-items-center flex-wrap justify-content-start mb-1 mb-md-0 mt-1"
      >
        <label>Entrées</label>

        <v-select
          v-model="state.perPage"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="perPageOptions"
          :clearable="false"
          class="per-page-selector d-inline-block ml-50 mr-1"
        />

        <b-button variant="primary" :to="{ name: 'gsMySupplyStore' }">
          <feather-icon icon="PlusIcon" class="mx-auto" />
          M'approvisionner
        </b-button>
      </b-col>

      <!-- Search -->

      <b-col cols="12" md="6" class="mt-1">
        <div class="d-flex align-items-center justify-content-end">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-prepend>
            <b-form-input
              v-model="state.filtre"
              class="d-inline-block mr-1"
              placeholder="Rechercher par : code de vente, type, prix, date..."
            />
          </b-input-group>
        </div>
      </b-col>
    </b-row>

    <q-loader-table
      :success="state.success"
      :empty="state.empty"
      :warring="state.warring"
    />

    <b-table
      hover
      responsive
      primary-key="id"
      :per-page="state.perPage"
      :current-page="state.currentPage"
      :items="supply"
      :fields="tableColumns"
      :filter="state.filter"
      show-empty
      empty-text="Aucun article enregistré"
      class="bg-white text-left"
      v-if="state.success === true"
    >
      <template #cell(code_facture)="data">
        <div class="py-1">
          <span> {{ data.item.code_facture }} </span>
        </div>
      </template>

      <template #cell(article)="data">
        <div class="py-1">
          <div
            class="w-100 p-50 bg-secondary text-white"
            style="
              font-size: 12px;
              display: grid !important;
              grid-template-columns: repeat(3, 1fr) !important;
            "
          >
            <span class=""> Libelle </span>
            <span class="text-center"> Quantité </span>
            <span class="d-flex justify-content-end"> Prix </span>
          </div>
          <div class="" v-for="item in data.item.articles" :key="item.id">
            <div
              class="d-flex justify-content-between border-top py-50 px-50"
              style="
                display: grid !important;
                grid-template-columns: repeat(3, 1fr) !important;
              "
            >
              <span> {{ item.libelle }} </span>
              <span class="text-center"> {{ item.qte }} </span>
              <span class="d-flex justify-content-end">
                {{ item.prix_vente }}
              </span>
            </div>
          </div>
        </div>
      </template>

      <template #cell(actions)="data">
        <div class="text-nowrap py-1">
          <feather-icon
            @click="updateSupply(data.item)"
            v-b-modal.e-update-article
            icon="Edit3Icon"
            size="16"
            class="cursor-pointer"
          />

          <feather-icon
            @click="destorySupply(data.item.id)"
            icon="TrashIcon"
            class="ml-1 cursor-pointer"
            size="16"
          />

          <!-- Dropdown -->
          <b-dropdown
            variant="link"
            toggle-class="p-0"
            no-caret
            :right="$store.state.appConfig.isRTL"
            disabled
            class="hidden"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <!-- <b-dropdown-item @click="editFac(data.item.id)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Modifier</span>
            </b-dropdown-item> -->
            <b-dropdown-item disabled>
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50"> Supprimer</span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>
    </b-table>

    <!-- Les boutons de pagination -->

    <div class="mx-2 mb-2 pb-1">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted"></span>
        </b-col>

        <!-- Pagination -->

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="state.currentPage"
            :total-rows="supply.length"
            :per-page="state.perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>

            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>

    {{ __OnRealTime }}
  </b-card>
</template>

<script>
/* eslint-disable global-require */
import {
  BRow,
  BCol,
  BLink,
  BButton,
  BModal,
  VBModal,
  BForm,
  BInputGroupPrepend,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BImg,
  BCardTitle,
  BCardText,
} from "bootstrap-vue";
import axios from "axios";
import { onMounted, reactive, ref, computed } from "@vue/composition-api";
import QLoaderTable from "@/components/__partials/loaders/qLoaderTable.vue";
import Article from "../dashboard/article/article.vue";
import vSelect from "vue-select";
import URL from "../request";
import moment from "moment";
import qDeviseUtils from "@/utils/qDeviseUtils";
import { qDecrypted } from "@/services/qeCrypt";
import { toast_destory } from "@/utils/qToast";

export default {
  components: {
    BInputGroupPrepend,
    BRow,
    BImg,
    BCol,
    BLink,
    BModal,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    VBModal,
    QLoaderTable,
    vSelect,
    Article,
  },
  setup(props, { root }) {
    // const providerArticle = Article.setup(props, { root });
    const supply = ref([]);
    const state = reactive({
      perPage: 30,
      currentPage: 1,
      filter: "",
      success: false,
      empty: false,
      warring: false,
      loading: false,
    });
    const perPageOptions = ["30"];
    const GIVE = ref(false);
    const tableColumns = reactive([
      {
        key: "libelle",
        label: "Libelle",
      },
      {
        key: "article",
        label: "articles",
        tdClass: "nameOfTheClass",
      },
      // {
      //   key: "fournisseur",
      //   label: "fournisseur",
      // },
      {
        key: "created_at",
        label: "Date de création",
      },
      {
        key: "actions",
        label: "Actions",
      },
    ]);

    onMounted(async () => {
      await listSupply();
    });
    const __OnRealTime = computed(() => {
      if (GIVE.value === true) {
        supply.value.length === 0
          ? ((state.empty = true), (state.success = false))
          : ((state.success = true), (state.empty = false));
      }
    });

    const listSupply = async () => {
      try {
        const { data } = await axios.get(URL.SUPPLY_LIST);
        console.log(data);
        const supply__ = [];
        if (data) {
          GIVE.value = true;
          for (let i = 0; i < data.approvisionnement.length; i++) {
            const sale = data.approvisionnement[i];
            sale.created_at = moment(sale.created_at).format("DD-MM-YYYY");
            sale.prix_total = formatter(parseInt(sale.prix_total));
            sale.code_facture = `${sale.code_facture}`.replace("FAC", "VEN");
            supply__.push(sale);
          }

          supply.value = data.approvisionnement;
          console.log(supply.value);
        }
      } catch (error) {
        state.success = false;
        state.warring = true;
        console.log(error);
      }
    };

    const destorySupply = async (id) => {
      root
        .$swal({
          title: `Êtes vous sûr !?`,

          text: "Aucun retour en arriére ne sera posible",

          icon: "warning",

          showCancelButton: true,

          confirmButtonText: "Oui",

          customClass: {
            confirmButton: "btn btn-primary",

            cancelButton: "btn btn-outline-danger ml-1",
          },

          buttonsStyling: false,
        })
        .then(async (response) => {
          if (response.isConfirmed === true) {
            try {
              const { data } = await axios.post(URL.SUPPLY_DESTROY, { id: id });
              if (data) {
                supply.value = supply.value.filter((sup) => {
                  return sup.id !== id;
                });
                toast_destory(
                  root,
                  "success",
                  "top-right",
                  `Article supprimer avec succès !`
                );
              }
            } catch (error) {
              state.success = false;
              state.warring = true;
              console.log(error);
            }
          }
        });
    };

    const updateSupply = async (id) => {};

    const formatter = (amount) => {
      const dataToDecrypt = localStorage.getItem("entreprise_info");
      return qDeviseUtils.formatter(qDecrypted(dataToDecrypt), amount);
    };

    return {
      state,
      supply,
      tableColumns,
      perPageOptions,
      __OnRealTime,
      updateSupply,
      destorySupply,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.nameOfTheClass {
  width: 500px !important;
}
</style>
